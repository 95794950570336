import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>{" "}
        <div className="bg" id="bg00" />
        <div className="bg" id="bg01" />
        <div className="bg" id="bg02" />
        <div className="bg" id="bg04" />{" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>{" "}
                </div>
              </div>
              <div className="slideshow_content">
                <div className="slideitem slide00">
                  <h2
                    style={{
                      width: "610px"
                    }}
                  >
                    Reisedurchfall?
                    <br />
                    IMODIUM®!
                  </h2>
                  <p>
                    <em>
                      IMODIUM® lingual Schmelztabletten sind ohne
                      Wasser einzunehmen und deswegen besonders praktisch auf
                      Reisen.
                      <br />
                      Jetzt Imodium lingual für die Reiseapotheke kaufen und den Urlaub mit einer Sorge weniger genießen können
                    </em>
                  </p>
                  <a className="btn" href="/imodium-lingual/">
                    <span className="start">Mehr erfahren</span>
                    <span className="end" />
                  </a>
                </div>
                <div className="slideitem slide01">
                  <h2>
                    SCHNELLE* HILFE
                    <br />
                    BEI DURCHFALL
                  </h2>
                  <p>
                    <em>
                      Imodium® lingual Schmelztabletten schmelzen
                      sofort auf der Zunge. Da sie ohne Wasser einzunehmen sind,
                      sind
                      <br />
                      sie ideal für unterwegs.
                    </em>
                  </p>
                  <a className="btn" href="/imodium-lingual/">
                    <span className="start">Mehr erfahren</span>
                    <span className="end" />
                  </a>
                  <p className="tip">
                    <em>*Die Wirkung kann nach etwa 2 Stunden eintreten</em>
                  </p>
                </div>
                <div className="slideitem slide02">
                  <h2>
                    Wie wirkt Imodium®
                  </h2>
                  <p>
                    <em>
                      Unser Film zeigt Ihnen, wie Durchfall Ihrem Körper
                      wichtige Flüssigkeit und Elektrolyte entzieht und wie
                      IMODIUM® bei Durchfall helfen kann.
                    </em>
                  </p>
                  <a className="btn" href="/loperamid/">
                    <span className="start">Mehr erfahren</span>
                    <span className="end" />
                  </a>
                </div>
                <div className="slideitem slide04">
                  <h2>Stress und Durchfall</h2>
                  <p>
                    <em>
                      Viele wissen nicht, dass Stress und Nervosität Auslöser
                      von akutem Durchfall sein können. Erfahren Sie hier mehr
                      über die Ursachen von Stress und stressbedingtem Durchfall
                      und was Sie tun können, um diese zu vermeiden.
                    </em>
                  </p>
                  <a className="btn" href="/stress-durchfall/">
                    <span className="start">Mehr erfahren</span>
                    <span className="end" />
                  </a>
                </div>
              </div>
              <div id="slideshow_navigation">
                <a id="slide00" className="active">
                  Reisedurchfall
                  <sup />
                </a>
                <a id="slide01">
                  Imodium® lingual
                </a>
                <a id="slide02">
                  Wie wirkt Imodium®
                </a>
                <a id="slide04">Stress und Durchfall</a>
                <div className="bar" />
              </div>{" "}
            </div>
          </div>
          <div id="content">
            <div id="start_teaser">
              <div className="region region-teaser">
                <div id="block-block-19" className="block block-block">
                  <div className="content">
                    <div className="item">
                      <div className="left">
                        <h2>Durchfall Fakten</h2>
                        <p>
                          <em>
                            Erfahren Sie mehr über die Ursachen &amp; Behandlung
                            von Durchfall
                          </em>
                        </p>
                      </div>
                      <div className="right">
                        <a href="/fakten-uber-durchfall/">
                          <img
                            alt
                            src="/sites/default/files/teaser_bauch_startseite.png"
                            style={{
                              width: "231px",
                              height: "146px"
                            }}
                          />
                        </a>
                      </div>
                      <div className="clear">&nbsp;</div>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div id="block-block-7" className="block block-block">
                  <div className="content">
                    <div className="item">
                      <div className="left">
                        <h2>FAQ´s</h2>
                      </div>
                      <div className="right">
                        <em>
                          Hier finden Sie die häufigsten Fragen und Antworten zu
                          Durchfall und IMODIUM®.
                        </em>
                      </div>
                      <div className="right">
                        <a className="arrow_01" href="/faq-page/">
                          Mehr erfahren
                        </a>
                      </div>
                      <div className="clear">&nbsp;</div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="start_special">
              <h3>
                DIE IMODIUM®
                <br />
                REISE-CHECKLISTE
              </h3>
              <div className="specialbox">
                <div
                  style={{
                    "font-size": "16px",
                    "line-height": "20px"
                  }}
                >
                  Bestens vorbereitet für die kommende Reise. In unserer
                  Reise-Checkliste finden Sie Medikamente für
                  die Reiseapotheke.
                </div>
                <div
                  style={{
                    "-webkit-text-align": "center",
                    "text-align": "center",
                    margin: "30px 0 10px 0"
                  }}
                >
                  <img src="/sites/default/files/thumbnail_front_reise_checkliste_2018.jpg" />
                </div>
                <div>
                  <a
                    className="btn nextbtn"
                    href="/sites/default/files/Checkliste_Reiseapotheke_CH_12-2018_2.pdf"
                    target="_blank"
                    fakeonclick="Javascript:epaper_reise_download();"
                  >
                    <span className="start">Checklist downloaden</span>
                    <span className="end" />
                  </a>
                </div>
                <div className="clear" />
              </div>
            </div>
            <div id="epaperlayer">
              <div id="guidebook-container">
                <div
                  id="dimmer"
                  style={{
                    "z-index": "11"
                  }}
                />
                <div id="guidebook">
                  <div
                    style={{
                      background: "#ffffff",
                      padding: "20px"
                    }}
                  >
                    <h3>Please install the newest Flash Player</h3>
                    <a href="https://www.adobe.com/go/getflashplayer">
                      <img
                        src="https://www.adobe.com/images/shared/download_buttons/get_flash_player.gif"
                        alt="Get Adobe Flash player"
                        border="0"
                      />
                    </a>
                    <br />
                    <br />
                    <a>Schließen</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clear" />
          <script type="text/javascript" src="/swfobject.js" />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                "\n//var vars = { };\nvar params = { wmode: \"transparent\", allowfullscreen: \"true\", allowScriptAccess: \"always\" };\nvar guidebook = '#guidebook';\nvar container = guidebook + '-container';\njQuery(document).ready(function() {\njQuery('#dimmer').css('z-index','3');\nvar hoehe = jQuery(window).height();\nvar breite = jQuery(window).width();\njQuery('#epaper_reise_open').click(function() {\nepaper_reise_open();\nif(jQuery(container).css('display') == \"none\") {\nswfobject.embedSWF(\"/epaper/reise/preloader.swf\", guidebook.replace(\"#\", \"\"), \"100%\", hoehe, \"9.0.0\", \"swf/expressInstall.swf\", {\"pageflipSwfPath\": \"/epaper/reise/pageflip.swf\", 'xmlPath': '/epaper/reise/xml/config.xml'}, params);\njQuery(container).css('display', 'block').stop().animate({ opacity: 1 }, 1000, function() {\njQuery(guidebook).css('display', 'block').attr('name', guidebook.replace(\"#\", \"\")).focus();\n});\njQuery('#guidebook').css('height', hoehe+'px');\n}\n});\n})\njQuery(document).keyup(function(e) {\nif(jQuery(container).css('display') == 'block') {\nvar obj = swfobject.getObjectById(guidebook.replace(\"#\", \"\"));\nswitch(e.keyCode) {\ncase 27: closeGuidebook();      // esc\nbreak;\ncase 37: obj.openPrev();    \t// left\nbreak;\ncase 39: obj.openNext();\t    // right\nbreak;\n}\n}\n})\nfunction closeGuidebook() { // guidebook ausblenden, funktion wird ueber flash aufgerufen\njQuery(guidebook).css('display', 'none');\njQuery(container).stop().animate({ opacity: 0 }, 850, function() { jQuery(this).css('display', 'none') });\n}\n"
            }}
          />
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <div id="videolayer">
          <div className="close modalClose" />
          <div className="videoplayer">
            <div id="flash_videoplayer">
              <h1>
                IMODIUM@ SPOT: Hier können Sie unseren aktuellen Apothekenfilm
                ansehen
              </h1>
              <br />
              <br />
              Please install the newest Flash Player
              <br />
              <br />
              <a href="https://www.adobe.com/go/getflashplayer">
                <img
                  src="https://www.adobe.com/images/shared/download_buttons/get_flash_player.gif"
                  alt="Get Adobe Flash player"
                  border="0"
                />
              </a>
            </div>
          </div>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                '\nfunction closeVideo(){\nalert("closeVideo() called!");\n}\nvar flashvars = {\nvideoUrlHigh: "../../assets/imodium_pos_2011_16z9.mp4",\nvideoPlayerWidth: "428",\nvideoPlayerHeight: "236"\n};\nvar params = {\nallowFullScreen: "true",\nallowScriptAccess: "always",\nbgcolor: "#000000",\nquality: "high",\nwmode: "transparent"\n};\nswfobject.embedSWF("resources/swf/videoplayer.swf", "flash_videoplayer", "428", "236", "10", "../resources/swf/expressInstall.swf", flashvars, params);\n'
            }}
          />
        </div>

      </div>
    );
  }
}

export default Page;
